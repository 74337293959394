/**
 * パスワード統合ステータス
 * 1:APPとECのユーザーパスワード不一致 2:APPとECのパスワード一致
 * @type {{NOT_MATCH: number, MATCH: number}}
 */
export const PASSWORD_INTEGRATION_STATUS = {
    NOT_MATCH: 1,
    MATCH: 2,
}

/**
 * 共通ダイアログのタイプ
 * @type {{GO_TO_LOGIN: number, RELOAD: number, GO_TO_LOST: number, BACK: number, CLOSE: number, OK: number, GO_TO_COMMUNE_LOGIN: number, GO_TO_USE: number}}
 */
export const DIALOG_TYPE = {
    CLOSE: 1,
    RELOAD: 2,
    OK: 3,
    BACK: 4,
    GO_TO_USE: 5,
    GO_TO_LOST: 6,
    GO_TO_LOGIN: 7,
    GO_TO_COMMUNE_LOGIN: 8,
}

/**
 * 登録フラグ（Commune用）
 * @type {{NO: string, YES: string}}
 */
export const IS_REGIST = {
    YES: '1',
    NO: '2',
}